.videoContainer {
    flex: 1;
    display: flex;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-position: center;
}

.textModal {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: rgb(111 149 113 / 27%);
    align-items: center;
    justify-content: center;
}